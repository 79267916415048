<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Determine the name of the acid described by each of the formulas below:</p>

      <p class="mb-2">
        a)
        <chemical-latex :content="formulaA" />
      </p>
      <v-text-field v-model="inputs.compoundA" outlined class="mb-n1" :disabled="!allowEditing" />

      <p class="mb-2">
        b)
        <chemical-latex :content="formulaB" />
      </p>
      <v-text-field v-model="inputs.compoundB" outlined class="mb-n1" :disabled="!allowEditing" />

      <p class="mb-2">
        c)
        <chemical-latex :content="formulaC" />
      </p>
      <v-text-field v-model="inputs.compoundC" outlined class="mb-n1" :disabled="!allowEditing" />

      <p class="mb-2">
        d)
        <chemical-latex :content="formulaD" />
      </p>
      <v-text-field v-model="inputs.compoundD" outlined class="mb-n3" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question380',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        compoundA: null,
        compoundB: null,
        compoundC: null,
        compoundD: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    formulaA() {
      if (this.versionVariableA.value === 1) {
        return 'HClO4(aq)';
      } else if (this.versionVariableA.value === 2) {
        return 'HIO3(aq)';
      } else if (this.versionVariableA.value === 3) {
        return 'HBrO2(aq)';
      } else if (this.versionVariableA.value === 4) {
        return 'HClO(aq)';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    formulaB() {
      if (this.versionVariableB.value === 1) {
        return 'HCl(aq)';
      } else if (this.versionVariableB.value === 2) {
        return 'HBr(aq)';
      } else if (this.versionVariableB.value === 3) {
        return 'HF(aq)';
      } else if (this.versionVariableB.value === 4) {
        return 'HI(aq)';
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    formulaC() {
      if (this.versionVariableC.value === 1) {
        return 'HNO3(aq)';
      } else if (this.versionVariableC.value === 2) {
        return 'H2CO3(aq)';
      } else if (this.versionVariableC.value === 3) {
        return 'CH3COOH(aq)';
      } else if (this.versionVariableC.value === 4) {
        return 'HCN(aq)';
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    formulaD() {
      if (this.versionVariableD.value === 1) {
        return 'H3PO4';
      } else if (this.versionVariableD.value === 2) {
        return 'H3PO3';
      } else if (this.versionVariableD.value === 3) {
        return 'H2SO4';
      } else if (this.versionVariableD.value === 4) {
        return 'H2SO3';
      } else {
        return '';
      }
    },
  },
};
</script>
